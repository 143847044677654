import { signInWithEmailAndPassword, getAuth } from 'firebase/auth'
import { initializeApp } from 'firebase/app'

const firebaseApp = initializeApp({
    apiKey: 'AIzaSyBfuAbh_Hkc2sBzGeXtnsGfHswA-n7Ymj0',
    authDomain: 'inhaus-f65a8.firebaseapp.com',
    databaseURL: 'https://inhaus-f65a8-default-rtdb.firebaseio.com',
    projectId: 'inhaus-f65a8',
    storageBucket: 'inhaus-f65a8.appspot.com',
    messagingSenderId: '10190002914',
    appId: '1:10190002914:web:08b4a2b407',
})
const firebaseAuth = getAuth(firebaseApp)
export interface Authentication {
    name: string
    email: string
    jwt: JWT
    role: string
    teamId: string
}

export interface JWT {
    token: string
    expiresAt: number
}

export class AuthService {
    public async authenticate(username: string, password: string): Promise<Authentication> {
        const auth = await signInWithEmailAndPassword(firebaseAuth, username, password)
        const token = await auth.user.getIdToken(true)

        const tokenPayload = JSON.parse(atob(token.split('.')[1]))
        const role = tokenPayload.role
        const teamId = tokenPayload.teamId

        return {
            name: auth.user.displayName as string,
            email: auth.user.email as string,
            role: role,
            teamId: teamId,
            jwt: {
                token,
                expiresAt: new Date().getTime() + 60 * 60 * 1000,
            },
        }
    }

    public async refreshToken(): Promise<string> {
        const user = firebaseAuth.currentUser;
        if (user) {
            return user.getIdToken(true); // This forces a refresh
        } else {
            throw new Error("No user is currently signed in.");
        }
    }
}
