import { defineAsyncComponent } from "vue";
// import ErrorComponent from "./error.vue";
// import LoadingComponent from "./loading.vue";

export default defineAsyncComponent({
    loader: () => import("./component.vue"),
    delay: 0,
    timeout: 5000,
    suspensible: true,
    // loadingComponent: LoadingComponent,
    // errorComponent: ErrorComponent,
})