import { useAuthStore } from '@/stores/auth'
import { Routes } from '@/router/Routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const auth = useAuthStore()
    if (auth.isAuthenticated) {
        next()
    } else {
        next({
            name: Routes.SignIn,
            query: {
                redirect: to.fullPath,
            },
        })
    }
}
