export enum Routes {
    Home = '/',
    SignIn = 'signin',
    SignUp = 'signup',
    Recover = 'recover',
    Photographer = 'photographer',
    PhotographerProject = 'photographer-project',
    ArtDirector = 'artdirector',
    ArtDirectorReviewReferenceImage = 'artdirector-check-reference-image',
    ArtDirectorProject = 'artdirector-project',
    ArtDirectorProjectSubmission = 'artdirector-project-submission',
    ArtDirectorRevision = 'artdirector-revision',
    ArtDirectorReshootProject = 'artdirector-reshoot-project',
    QualityAssurance = 'quality-assurance',
    QualityAssuranceDashboard = 'quality-assurance-dashboard',
    QualityAssuranceReviewReferenceImage = 'quality-assurance-review-reference-image',
    QualityAssuranceReviewSelection = 'quality-assurance-review-selection',
    QualityAssuranceReshootProject = 'quality-assurance-reshoot-project',
    AdminJobs = 'admin-jobs',
    AdminSettings = 'admin-settings',
    AdminDashboard = 'admin-dashboard',
    AdminReports = 'admin-reports',
    NotFound = 'not-found',
    Unauthorized = 'unauthorized',
    Projects = 'projects',


    

}
