import { useAuthStore } from '@/stores/auth'
import { Routes } from '../Routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const authStore = useAuthStore()
    if (authStore.isAuthenticated) {
        return next(Routes.Home)
    }
    return next()
}
