import router from '../router';

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';


interface Filters {
    currentPage: number
    search: string,
    documentType: string,
    studio:  object[],
    recipe: object[],
    statuses: SelectedAdminStatus,
    searchDateStart: string,
    searchDateEnd:string
}

interface SelectedAdminStatus {
    qa: number[];
    art: number[];
}

export const useFilterStore = defineStore('filters', () => {
    let tokenRefreshTimer: NodeJS.Timeout | undefined;
    const filter = ref<Filters>({
        currentPage: 0,
        search: '',
        documentType: '',
        recipe: [],
        studio: [],
        statuses: {qa: [], art:[]},
        searchDateStart: '01.01.1970',
        searchDateEnd: new Date().toDateString()
    });

    // Setters
    const setFilter = (key:any, value:any) => {
        
        filter.value[key] = value
        console.log("filter.value",key,value)
        localStorage.setItem('filters', JSON.stringify(filter.value))
    }

    const getFilters = async () => {
        
         filter.value = await JSON.parse(localStorage.getItem('filters'))
       
         const filterNew = await JSON.parse(localStorage.getItem('filters'))
         return filterNew
    }

    const resetFilter = () => {
        filter.value = {
            currentPage: 0,
            search: '',
            documentType: '',
            recipe: [],
            studio: [],
            statuses: {qa:[],art:[]},
            searchDateStart: '01.01.1970',
            searchDateEnd: new Date().toDateString()
        }
        console.log(filter.value)
        localStorage.setItem('filters', JSON.stringify(filter.value))
       
    }

   
    
    // Return
    return {
        filter,
        setFilter,
        resetFilter,
        getFilters,
        
    };
});
