import { useAuthStore } from '@/stores/auth'
import { Routes } from '@/router/Routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    console.log('from', from)
    console.log('to', to)
    console.log(`${String(from.name)} -> ${String(to.name)}`)
    next()
}
