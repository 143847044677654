<script lang="ts">
export default {
    name: 'HeaderContent',
}
</script>
<script setup lang="ts">
import {onMounted} from "vue"
import { Container, Button, Dropdown, Card, ButtonGroup } from '@inhaus-ai-limited/vue-components'
import { useAuthStore } from '../../stores/auth'
import router from '@/router'
import HeroIcon from '@/components/HeroIcon.vue'
import { Routes } from '@/router/Routes'
import { useRoute } from 'vue-router'
import { useFilterStore } from "@/stores/filters"
import { useRefStore } from '@/stores/refStore'


const auth = useAuthStore()
const route = useRoute()
const filter = useFilterStore()
const refStore = useRefStore()


// const projectPaginationStore = useProjectPaginationStore()

const signout = () => {
    filter.resetFilter();
    refStore.reset();
    auth.signOut(false);
}


onMounted(() => {
    setInterval(() => {
        if(auth.jwt.expiresAt != 0 && auth.jwt.expiresAt < new Date().getTime()) {
            console.log("!Authenticationsss",router.currentRoute )
            auth.signOut(true)
            window.location.reload()
            // auth.setupTokenRefresh();
            // window.location.reload()

        }else {
            console.log("Authentication",router.currentRoute.value.fullPath )
        }
    }, 10000)
})

</script>

<template>
    <Container class="grid grid-cols-3">
        <Container class="flex flex-col justify-center">
            <Button class="float-left max-w-fit" variant="label" color="secondary"
                v-if="auth.isAuthenticated && $route.name !== 'artdirector'">
                <HeroIcon icon="ChevronLeftIcon" class="w-20 h-20 rounded-sm inline-block" />
                <span class="text-base w-44 h-28 font-bold ml-[.5vw]" @click="router.back()">Back</span>
            </Button>
        </Container>
        <Container class="flex flex-col justify-center mx-auto">
            <Button variant="label" color="secondary" class="flex-auto justify-center items-center" @click="
                router.push({
                    name: Routes.Home,
                    force: true,
                })
                ">
                <img src="@/assets/logo.png" alt="InHaus.ai" class="w-94 h-25 mx-auto" />
            </Button>
        </Container>
        <Container class="flex flex-col justify-center">
            <!-- <ButtonGroup v-if="!auth.isAuthenticated" class="flex my-auto justify-end h-fit space-x-[5px]"> -->
            <!-- <Button
                    class="w-[100px]"
                    variant="outline"
                    size="md"
                    color="secondary"
                    @click="
                        router.push({
                            name: Routes.SignUp,
                        })
                    "
                    >Sign up</Button
                > -->
            <!-- <Button
                    class="w-[100px] max-w-[120px] border-white border-[1px]"
                    variant="default"
                    size="md"
                    color="primary"
                    @click="
                        router.push({
                            name: Routes.SignIn,
                        })
                    "
                    >Log-in</Button
                > -->
            <!-- </ButtonGroup> -->
            <div v-if="auth.isAuthenticated" class="flex my-auto justify-end h-fit">
                <Dropdown :fixed="true" class="max-w-fit">
                    <template #trigger="{ toggle, isVisible }">
                        <Button size="lg" color="secondary" @click="toggle"
                            class="space-x-5 justify-center items-center">
                            <span class="ml-2 text-black font-semibold">{{ auth.name || auth.email }}</span>
                            <img src="@/assets/avatar.svg" alt="avatar.svg" width="30" height="30" />
                            <HeroIcon v-if="isVisible" icon="ChevronUpIcon"
                                class="w-20 h-20 rounded-sm inline-block float-right" />
                            <HeroIcon v-else icon="ChevronDownIcon"
                                class="w-20 h-20 rounded-sm inline-block float-right" />
                        </Button>
                    </template>
                    <Card :square="true" :shadow="true">
                        <ul>
                            <li v-if="auth?.role !== 'admin'">
                                <Button textPosition="left" size="lg" color="secondary"
                                    :class="route.name == Routes.Home || route.fullPath == '/admin/dashboard' || route.fullPath == '/photographer' || route.fullPath == '/artdirector' ? 'text-left text-black' : 'text-left text-gray-400'"
                                    @click="
                                        router.push({
                                            name: Routes.Home,
                                        })
                                        ">
                                    <HeroIcon icon="HomeIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Completed</span>
                                </Button>
                            </li>
                            <li v-if="auth?.role === 'admin'">
                                <Button textPosition="left" size="lg" color="secondary"
                                    :class="route.name == Routes.Projects ? 'text-left text-black' : 'text-left text-gray-400'"
                                    @click="
                                        router.push({
                                            name: Routes.Projects,
                                        })
                                        ">
                                    <HeroIcon icon="HomeIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Projects</span>
                                </Button>
                            </li>

                            <li>
                                <Button textPosition="left" size="lg" color="secondary"
                                    :class="route.name == Routes.AdminJobs ? 'text-left text-black' : 'text-left text-gray-400'"
                                    @click="
                                        router.push({
                                            name: Routes.AdminJobs,
                                        })
                                        ">
                                    <HeroIcon icon="BriefcaseIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Jobs</span>
                                </Button>
                            </li>
                            <li v-if="auth?.role === 'admin'">
                                <Button textPosition="left" size="lg" color="secondary"
                                    :class="route.name == Routes.Home || route.fullPath == '/admin/dashboard' || route.fullPath == '/photographer' || route.fullPath == '/artdirector' ? 'text-left text-black' : 'text-left text-gray-400'"
                                    @click="
                                        router.push({
                                            name: Routes.AdminDashboard,
                                        })
                                        ">
                                    <HeroIcon icon="HomeIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Completed</span>
                                </Button>
                            </li>

                            <!--                            <li v-if="auth?.role === 'admin'">-->
                            <!--                                <Button-->
                            <!--                                    textPosition="left"-->
                            <!--                                    size="lg"-->
                            <!--                                    color="secondary"-->
                            <!--                                    class="text-left"-->
                            <!--                                    @click="-->
                            <!--                                        router.push({-->
                            <!--                                            name: Routes.QualityAssurance,-->
                            <!--                                        })-->
                            <!--                                    "-->
                            <!--                                >-->
                            <!--                                    <HeroIcon icon="HomeIcon" class="w-25 h-25 inline-block" />-->
                            <!--                                    <span class="ml-10 font-medium">Quality Assurance</span>-->
                            <!--                                </Button>-->
                            <!--                            </li>-->

                            <li v-if="auth?.role === 'admin'">
                                <Button textPosition="left" size="lg" color="secondary"
                                    :class="route.name == Routes.AdminSettings ? 'text-left text-black' : 'text-left text-gray-400'"
                                    @click="
                                        router.push({
                                            name: Routes.AdminSettings,
                                        })
                                        ">
                                    <HeroIcon icon="CogIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Settings</span>
                                </Button>
                            </li>
                            <li>
                                <Button textPosition="left" size="lg" color="secondary"
                                    :class="route.name == Routes.AdminReports ? 'text-left text-black' : 'text-left text-gray-400'"
                                    @click="
                                        router.push({
                                            name: Routes.AdminReports,
                                        })
                                        ">
                                    <HeroIcon icon="DocumentTextIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Reports</span>
                                </Button>
                            </li>
                            <li>
                                <Button textPosition="left" size="lg" color="secondary" class=" text-left text-gray-400"
                                    @click="() => { signout() }">
                                    <HeroIcon icon="LogoutIcon" class="w-25 h-25 inline-block" />
                                    <span class="ml-10 font-medium">Logout</span>
                                </Button>
                            </li>
                        </ul>
                    </Card>
                </Dropdown>
            </div>
        </Container>
    </Container>
</template>
