<script setup lang="ts">
import * as HeroIcons from "@heroicons/vue/solid";
import { type PropType, ref } from "vue";
export type IconName = keyof typeof HeroIcons;

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
});

const DynamicHeroIcon = HeroIcons[props.icon as IconName];
if (!DynamicHeroIcon) {
  throw new Error(`Invalid icon name: ${props.icon}`);
}
</script>

<template>
  <component :is="DynamicHeroIcon" />
</template>