<script setup lang="ts">
import { RouterView } from 'vue-router'
// import PWAPrompt from '@/components/PWAPrompt.vue'
import { useAuthStore } from './stores/auth'
import { App, AppHeader, Notification } from '@inhaus-ai-limited/vue-components'
import HeaderContent from './views/components/HeaderContent.vue'
import { onMounted } from 'vue';

const isProduction = () => import.meta.env.VITE_NODE_ENV === 'prod'
const VERSION = import.meta.env.VITE_VERSION

onMounted(() => {
    console.log('App mounted', VERSION)
})
</script>

<template>
    <App>
        <AppHeader class="w-full shadow-xl min-h-[60px] justify-between px-[40px] z-[1]">
            <HeaderContent />
        </AppHeader>
        <RouterView :key="$route.fullPath" v-slot="{ Component }" class="max-w-full max-h-full">
            <Suspense v-if="Component" suspensible>
                <component :is="Component" :key="Math.random()" />
            </Suspense>
        </RouterView>
        <!-- <PWAPrompt v-if="auth.isAuthenticated" /> -->
        <div v-if="!isProduction()" class="fixed bottom-0 left-0 bg-gray-500/50 text-white p-2">
            <span class="text-sm">Version: ({{ VERSION }})</span>
        </div>
    </App>
</template>

<style>
* {
    /* overflow: hidden; */
    /* border: solid 1px red !important; */
    box-sizing: border-box;
    font-family: 'Nunito Sans', sans-serif;
}

body,
#app {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

/**
  * Custom scrollbar
  */
::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: black;
}
</style>
