import { defineStore } from 'pinia'
import { computed, reactive, ref, type Ref } from 'vue'

export const useRefStore = defineStore(
    'refStore',
    () => {
        // State
        var states = reactive<{
            [key: string]: any
        }>({})

        // Getters
        const _ref = <T>(key: string, value: T) => {
            if (!states[key]) {
                states[key] = value
            }
            return new Proxy(states[key], {
                get(target, prop) {
                    if (prop === 'value') {
                        return states[key]
                    }
                    return Reflect.get(target, prop)
                },
                set(target, prop, value) {
                    if (prop === 'value') {
                        states[key] = value
                    }
                    return Reflect.set(target, prop, value)
                },
            }) as Ref<T>
        }

        const reset = async () => {
            
            Object.keys(states).forEach(key =>states[key] = undefined);
        }

        return {
            ref: _ref,
            states,
            reset
        }
    },
    {
        persist: {
            debug: true,
            beforeRestore: (ctx) => {
                return false
              },
            afterRestore: ({ store }) => {
                console.log('After restore', store)
            },
        },
    }
)
