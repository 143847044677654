import { useAuthStore } from '@/stores/auth'
import { Routes } from '@/router/Routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export default (...roles: string[]) => {
    return (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
        const auth = useAuthStore()
        if (auth.isAuthenticated && roles.includes(auth.role ?? '')) {
            next()
        } else {
            next({
                name: Routes.Unauthorized,
            })
        }
    }
}
